import React from "react"
import { injectIntl } from "gatsby-plugin-intl-v3"
import SEO from "../components/seo"

const NotFoundPage = ({intl}) => (
  <>
    <SEO />
    <h1>{intl.formatMessage({ id: "notfound.header" })}</h1>
    <p>{intl.formatMessage({ id: "notfound.description" })}</p>
  </>
)

export default injectIntl(NotFoundPage)
